.pointer {
  cursor: pointer;
}

.pass-list {
  list-style-image: url('../images/icons8-close.svg');
  width: 10;
  height: 10;
}

.pass-list li::marker {
  font-size: 0.5rem;
  /* You can use px, but I think rem is more respecful */
  height: 50px;
}

.passtick-list {
  list-style-image: url('../images/icons8-done.svg');
  width: 10;
  height: 10;
}

.passtick-list li::marker {
  font-size: 0.5rem;
  /* You can use px, but I think rem is more respecful */
}

.sidemenu-items .accordion-button {
  background: #ffffff;
  box-shadow: none;
  border: 0;

}

.sidemenu-items .accordion-button:active {
  background: #f6f6f6;
}

.bs-none {
  border: 'none'
}


.login-wrp .input-group .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 0;
}

.badge-style {
  background-color: #d6d5d5 !important;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.react-tel-input .form-control {
  height: 46px !important;
  width: 100% !important;
}

.react-tel-input :disabled {
  cursor: not-allowed;
  background: #e9ecef !important;
}

.dropzone-input {
  border: 1px solid #babfcb;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #d6d5d5;
}

.cursor-pointer {
  cursor: pointer;
}

.menu-arrow {
  position: absolute;
  right: 0;
  width: 13px !important;
  height: 13px !important;
  transition: all ease 0.25s;
}

.leftside-bar .sidebar-menu .sidemenu-items .collapsed-after .menu-arrow {
  transform: rotate(-90deg);
}

.err {
  min-height: 25px;
  display: block;
}

.spin {
  margin-top: 25%;
}

.loader {
  width: 3rem;
  height: 3rem
}

.h-30 {
  height: 30px;
}

.boxwrapper {
  width: 100%;
  max-width: 200px;
  border-color: #000;
}

.btnyesorno {
  background-color: #fff!important;
  border-style: none;
  box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 10%);
  color: #000!important;
  padding: 2px 21px;
  font-size: 16px;
}

.hint-list .menu-item {
  position: relative;
  list-style: none;
  padding: 4px 0 4px 35px;
}

.hint-list .menu-item::before {
  content: "";
  background-image: url('../images/hint.svg');
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 2px;
  background-size: contain;
}

li.menu-item {
  margin: 0 0 10px 0;
}

.text-blue {
  color: #045C87;
}

.fileWrapper {
  border-style: dotted;
  min-height: 150px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.error-border {
  border: '1px solid #dc3545 !important',
}

.activespan{
  background-color: #f6f6f6;
}

.user-images {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
}
.user-images .up-icon {
  position: absolute;
  bottom: 9px;
  right: 5px;
  background: #023047;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-images .up-icon svg {
  font-size: 20px;
}
.user-images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
  border: solid 1px #efefef;
}

.text-light-blue {
  color: #1e7ba9 !important;
}
.btn-highlight {
  box-shadow: 0 0 0 0.25rem rgba(40, 79, 99, 0.5);
}
.box-card {
    padding: 1.3rem 1rem 3rem 1rem;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1);
    height: 100%;
    border: solid 1px transparent;
}
.tooltip-inner {
  text-align: justify;
  text-justify: auto;  
}
.w-40 {
  width: 40%;
  flex: 0 0 40%;
}
.w-60 {
  width: 60%;
  flex: 0 0 60%;
}
.main-collapse {
  margin-bottom: 10px;
}
.main-collapse .accordion-item {
  border: 0;
}
.main-collapse .accordion-body {
  padding: 1rem 5px;
}
.main-collapse .accordion-button {
  border-radius: 7px;
    background: #f7f8f9;
    border: solid 1px #efefef;
}
.sub-collapse .accordion-button {
  background: #fff;
}
.sub-collapse .accordion-item {
  border: 1px;
}
.permission-text {
  font-weight: 600;
  padding-left: 1rem;
}
.permission-outer .title {
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 10px;
}
.topic-boxes {
  border-radius: 8px;
  background: #efefef;
  padding: 1rem 1rem 1rem 1.5rem;
}
.topic-title {
  font-size: 20px;
  font-weight: 600;
}
.permission-sub {
  margin-bottom: 2rem;
}
.title-border {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px !important;
}
.profile-det .profile-pic {
  background: #efefef;
}
.contents {
  min-height: calc(100vh - 100px);
}

.manage-access-box .profile-det .profile-pic {
  width:50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}
.wizard-stepcomplete{
  margin-right:3px !important
}
.tst-align{text-align: justify;}

.backImg{
  background-image: url('../images/background.jpg');
}
.logo-header{
   color:#4fa5d8;
}
.logo-sub{
  color: #3b3873;}
.cstm-checkbox-title{
  font-size: 16px !important;
  font-weight: 900 !important;
}
.cstm-checkbox-topic{
  font-size: 16px !important;
  font-weight: 500 !important;
}
.cstm-chip{
  border-radius: 10px;
  padding:4px 8px;
}

/* Example: Change the color of page numbers */
.react-paginate a {
  color: #333;
  text-decoration: none;
}

/* Example: Style the active page */
.react-paginate a.active {
  color: #fff;
  background-color: #007bff;
}

.searchbox{
  border: 1px solid #a1a1a1;
    border-radius: 20px;
    padding: 8px 16px;
}
.searchbox + i {
  position: absolute;
  top: 50%;
  right: 15px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  color: #555; /* Adjust the color of the icon */
  cursor: pointer;
}

.statusBarRed{
  text-align: center;
  font-weight: bold;
  color: red;
}
.statusBar-green{
  text-align: center;
  font-weight: bold;
  color: green;
}

.header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-content {
  display: inline-block; /* This ensures the ellipsis works properly */
}